<template>
  <div>
     <div class="contents">
       <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:10px 10px 15px">
    <el-breadcrumb-item :to="{ path: '/dashboard' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>开团大厅</el-breadcrumb-item>
    <el-breadcrumb-item>团基本信息</el-breadcrumb-item>
  </el-breadcrumb>
       <div class="mainbox">
    <div class="formTable chakanform">
     <el-form :model="goodArr" label-position="right" label-width="150px">
          <el-form-item label="团购名称：" prop="name" style="margin-bottom:10px" >
            <!-- <p class="pptext">{{goodArr.name}}</p> -->
            <el-input
              type="textarea"
              autosize
              readonly
              placeholder="请输入内容"
              v-model="goodArr.name">
            </el-input>
          </el-form-item>
          <el-form-item label="适用城市：" prop="name" style="margin-bottom:10px" >
            <div class="cityes">
            <template v-if="goodArr.cities.length > 0">
           <template v-for="items in goodArr.cityArr" v:key="items">
            <bdo v-if="items.city">{{items.city}}</bdo>
            </template> </template>
            
            <bdo v-else>全国</bdo>
            </div>
          </el-form-item>
          <el-form-item label="团购时间：" prop="date" style="margin-bottom:10px">
            <el-date-picker v-model="goodArr.date" disabled format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
         </el-form-item>
          <el-form-item label="团购封面图：" class="classol">
              <div class="groupMa">
                    <template v-for="(item,index) in goodArr.images">
                    <ol>
                       <el-image
                        class="avatarSize"
                        :src="item"
                        :preview-src-list="goodArr.images">
                      </el-image>
                      <!-- <img v-if="item" :src="item" class="avatarSize"> -->
                    </ol>
                </template>
              </div>
            </el-form-item>
           <el-form-item label="拼团二维码：">
              <div class="groupMa">
              <template v-for="(item,index) in groupCode">
                <ol>
                <el-upload
                class="avatar-uploaderss"
                action="string"
                :data="{n:index}"
                :disabled="disabled"
                :http-request="UploadSource"
                :show-file-list="false"
                :limit="1">
                <img v-if="item.resourceCode" :src="item.resourceCode" class="avatarSize">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span :label="item.code">{{item.value}}</span>
                </ol>
              </template>
              </div>
          </el-form-item>
          <el-form-item label="团购商品：">
           <template v-for="(item, index) in goodsData">
             <el-tag style="margin-right: 10px">{{item.name}}</el-tag>
           </template>  
          </el-form-item>
          <el-form-item label="团状态：">
            <p> {{goodArr.statusDescription}}</p>
          </el-form-item>
        </el-form>

        <p style="color:#eee; border-bottom: 1px solid #eee; margin-bottom:20px"></p>
        <el-form :model="supplier" label-position="right" label-width="150px">
          <el-form-item label="供应商名称：">
            <p> {{supplier.name}}</p>
          </el-form-item>
          <el-form-item label="供应商LOGO：">
            <img v-if="supplier.logo" :src="supplier.logo" />
            <p v-else>暂无</p>
          </el-form-item>
          <el-form-item label="联系人：">
            <p> {{supplier.contactName?supplier.contactName:'暂无'}}</p>
          </el-form-item>
           <el-form-item label="联系电话：">
            <p> {{supplier.contactPhone?supplier.contactPhone:'暂无'}}</p>
          </el-form-item>
        </el-form>
       </div>
       </div>   
    </div>
  </div>
</template>

<script>
import { uploadFile, dictConfig, getCity } from "@/api/common";
import { detailTuan, getTuanNums } from "@/api/kaituan";
let moment = require('moment');
export default {
  name: 'tuanMains',
  data() {
    return {
      classArr: [],
      goodArr: {
        id: '',
        name:'',
        date: [],
        categoryId: '',
        video: '',
        status: 1,
        cityArr: [],
        cities: [],
        // maxPrice: '',
        // minPrice: '',
        images: [],
        statusDescription: ''
      },
      supplier: {},
      checked: false,
      classflag: false,
      showgood: false,
      disabled: true,
      loading: false,
      groupCode: [],
      tableData: [],
      goodsData: [],
      page: 1,
      pageSize: 10,
      total:0,
      multipleSelection: [],
    }
  },
  created: function () {
    const end = new Date();
    const start = new Date();
    end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
    this.goodArr.date = [start, end];
  },
  methods:{
    addgood(){
      this.$router.push({
        path: "/goods/detail?gotourl=tuan"
      })
    },
    importGood(){
      this.showgood = true;
      this.getGoodList()
    },
    handleClose(){
      this.showgood = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDelArr(tag) {
      this.goodsData.splice(this.goodsData.indexOf(tag), 1);
    },
    handleSubmit(){
      var that = this;
      if(that.multipleSelection.length==0){
        that.$message.error('请选择商品')
        return;
      }else{
        that.multipleSelection.map((item)=>{
          var objs = {}
          objs.id = item.id
          objs.name = item.name
          that.goodsData.push(objs)
        })
        console.log(that.goodsData)
        setTimeout(() => {
          that.showgood = false;
        }, 300)
        
      }

    },
    getMaList(){
      var that = this
      var objs = {}
      objs.code = "group_bar_code"
      dictConfig(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.groupCode = res.data.dictList
        }else{
          this.$message.error(res.message)
        }
      })
    },
    getNumsList(id){
      var that = this
      var objs = {}
      objs.tuanId = id;
      objs.clientName = 'PC端';
      objs.clientType = "1";
      getTuanNums(objs).then(res => {
        console.log(res);
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getGoodList()
    },
    getTuanDetail(ids){
      var that = this
      var objs = {}
      objs.id = ids
      detailTuan(objs).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.goodArr.date = []
          that.goodArr.id = res.data.id
          that.goodArr.name = res.data.name
          that.goodArr.date[0] = res.data.startTime ? moment(res.data.startTime).format('YYYY-MM-DD 00:00:00'):''
          that.goodArr.date[1] = res.data.endTime ? moment(res.data.endTime).format('YYYY-MM-DD 23:59:59'):''
          console.log(that.goodArr.date)
          that.goodArr.statusDescription = res.data.statusDescription
          // that.goodArr.maxPrice = res.data.maxPrice
          // that.goodArr.minPrice = res.data.minPrice
          that.goodArr.images = res.data.images
          that.supplier = res.data.supplier
          if(res.data.barImages){
            that.groupCode = res.data.barImages
          }else{
            this.getMaList()
          }
          that.goodsData = res.data.goods
          that.goodArr.status = res.data.status
          that.goodArr.cities = res.data.cities
          that.goodArr.cityArr = that.uniqueFun(res.data.cities, 'city')
          that.getNumsList(ids)
        }else{
          this.$message.error(res.message)
        }
      })
    },
           //根据对象某个属性去重
    uniqueFun(arr, val){
      const res = new Map()
      return arr.filter(item =>!res.has(item[val]) && res.set(item[val], 1))
    },
    cancalFun(){
      this.$router.go(-1)
    },
    UploadImage(param){
      var that = this
      const formData = new FormData()
      console.log(param)
      var objs = {}
      formData.append('file', param.file)
      uploadFile(formData).then(res => {
      	console.log('上传图片成功')
        // param.onSuccess()  // 上传成功的图片会显示绿色的对勾
        that.goodArr.images.push(res.data.url)
      }).catch(response => {
        console.log('图片上传失败')
        param.onError()
      })
    },
    handleDel(n) {
      var that = this
      that.goodArr.images.splice(n,1)
    },
    UploadSource(param){
      var that = this
      const formData = new FormData()
      formData.append('file', param.file)
      uploadFile(formData).then(res => {
      	console.log('上传图片成功')
        // param.onSuccess()  // 上传成功的图片会显示绿色的对勾
        that.groupCode[param.data.n].resourceCode = res.data.url
        console.log(that.goodArr.barCodeUrls)
      }).catch(response => {
        console.log('图片上传失败')
        param.onError()
      })
    },
    UploadSizes(param){
      var that = this
      console.log(param)
      console.log('当前第几个'+ param.data.keys)
      const formData = new FormData()
      formData.append('file', param.file)
      uploadFile(formData).then(res => {
      	console.log('上传图片成功')
        console.log(that.skuArrs)
        // param.onSuccess()  // 上传成功的图片会显示绿色的对勾
        that.skuArrs[0].desc[param.data.keys].image = res.data.url
      }).catch(response => {
        console.log('图片上传失败')
        param.onError()
      })
    },
    addClass(){
      this.classflag = true
    },
    onCancalFun(vv){
      this.classflag = vv
      this.getCategoryList()
    },
    changeImg(e){
      console.log(e)
      this.checked = e
    },
    delhezi(n){
      console.log(n)
      this.skuArrs.splice(n, 1)
      this.showTable()
    },
    delfun(n, a){
      console.log(n, a)
      this.skuArrs[n].desc.splice(a, 1)
      this.showTd()
    },
    submitFun(){
      var that = this
      var params = {}
      if(!that.goodArr.name) { that.$message.error('请输入团购名称'); return false }
      if(!that.goodArr.date) { that.$message.error('请选择团购时间'); return false }
      params.images = that.goodArr.images || []
      params.name = that.goodArr.name
      params.startTime = that.goodArr.date ? moment(that.goodArr.date[0]).format('YYYY-MM-DD 00:00:00') : '';
      params.endTime = that.goodArr.date ? moment(that.goodArr.date[1]).format('YYYY-MM-DD 23:59:59') : '';
      params.status = that.goodArr.status
      params.barImages = that.groupCode
      params.goodsData = that.goodsData
      console.log(params)
      that.loading = true
      if(that.goodArr.id){
        params.id = that.goodArr.id
        editTuan(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '编辑成功',
              type: 'success'
            })
            setTimeout(() =>{
              that.$router.push({
                path: "/sendtuan"
              })
            }, 300)
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }else{
        addTuan(params).then(res => {
          if(res.code === 200){
            that.$message({
              message: '创建成功',
              type: 'success'
            })
            setTimeout(() =>{
              that.$router.push({
                path: "/sendtuan"
              })
            }, 300)
          }else{
            that.$message.error(res.message)
          }
          that.loading = false
        })
      }
    
    },
    changeRe(e){
      console.log(e)
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif';
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是JPG、JPEG、PNG、GIF格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isJPG && isLt2M;
    }
  },
  mounted() {
    var ids= this.$route.query.id
    console.log(ids)
    if(ids){
      this.getTuanDetail(ids)
    }
  }
}
</script>

<style scoped>
.el-form-item__label{
  color: #999;
  font-size: 12px;
}
.formTable{
  width: 70%;
  display: block;
  padding: 20px 50px 50px;
}
.cityes bdo{
  /* background-color: #fe4729; */
  padding: 0 6px;
  margin-right: 4px;
  display: inline-block;
  color: #fe4729;
  border-radius:4px;
  font-size: 13px;
  border: 1px solid #fe4729;
  margin-bottom: 2px;
}
.guigeBox{
 padding: 10px 0;
}
.guigeBox:hover{
  background-color: #f7f7f7;
}
.classInfo{
  font-size: 12px;padding-left: 20px; color: #999;
}
.groupMa{
  display: block;
  overflow: hidden;
  width: 100%;
}
.groupMa ol{
  float:left;
  margin: 0;
  padding: 0;
  width: 120px;
  text-align: left;
  font-size: 12px;
  margin-right: 10px;
  /* border: 1px solid #eee;
  padding: 2px; */
  position: relative;
}
.delfun{
  position: absolute;
  right: 0;top: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 18px;
  cursor: pointer;
  color: #fff;
  background-color: #07C160;
}
.groupMa ol span{
  display: block;
  text-align: center;
  margin-top: -10px;
  font-weight: 500;
  color: #666;
}
.subbox{
  /* padding: 20px; */
  border: 1px solid #eee;
  margin-bottom: 10px;
}
.uploadAdd{
  width: 100%;
  height: 60px;
  font-size: 18px;
}
.rightBtn{
  padding: 25px 50px 25px;
  float: right;
}
.duoguige{
  background-color: #f3f3f3;
  line-height: 24px;
  font-size: 15px;
  padding: 20px;
}
.duoguige span{
  float: right;
}
.setGuige{
  padding:20px;
}
.guiboxzi{
  display: inline-block;
  position: relative;
  width: 560px;
  margin-right: 20px;
}
.guigeLi{
  display: block;
  line-height: 44px;
  font-size: 14px;
  padding-left: 120px;
  overflow: hidden;
}
.guigeLi span{
  display: inline-block;
  width: 120px;
  text-align:right;
  margin-left: -120px;
}
.guigeLi ol, .addkuang{
  float: left;
  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}
.guigeLi .guigeDel{
  position: absolute;
  right: 6px;
  top: 16px;
  width:16px;
  height: 16px;
  background-color: #bbb;
  color: #fff;
  border-radius: 16px;
  text-align: center;
  line-height: 14px;
  cursor: pointer;
}
.guigeFen{
  padding-left: 120px;
  padding-top: 10px;
}
.guiTable{
  padding: 20px;
}
.sizeimgss{
  width: 60px;
  height: 60px;
  display: block;
}

</style>